/* MODALE 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

#modale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #0008;
    opacity: 0;
}

#modale.opened {
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.3s ease-in-out 0s;
}

#modale #modale-container {
    width: 60%;
    height: 80%;
    background-color: #333;
    border-radius: 7px;
    margin: 0 auto;
    top: 10%;
    position: relative;
    box-shadow: #1118 0px 0px 5px;
}

#modale #modale-container #modale-content {
    height: 100%;
}

#modale #modale-container #modale-content #modale-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    width: 30px;
}