main[data-page="annoncement"] #annonces-filter {
    box-shadow: var(--clear-thin-shadow-5);
    position: relative;
}

main[data-page="annoncement"] #annonces-filter .loading-gif {
    width: 30px;
    height: 30px;
}

@media (min-width: 0px) {
    main[data-page="annoncement"] #annonces-filter {
        display: block;
        width: 100%;
        padding: 10px 4px;
    }
}

@media (min-width: 768px) {
    main[data-page="annoncement"] #annonces-filter {
        display: block;
        flex: 3;
        padding: 10px 4px;
    }
}

@media (min-width: 1199px) {
    main[data-page="annoncement"] #annonces-filter {
        display: block;
        flex: 3;
        padding: 15px 8px;
    }
}

@media (min-width: 1200px) {
    main[data-page="annoncement"] #annonces-filter {
        display: block;
        flex: 2;
        padding: 25px 12px;
    }
}

main[data-page="annoncement"] #annonces-filter {

}

main[data-page="annoncement"] #annonces-filter h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

main[data-page="annoncement"] #annonces-filter .filter-zone {
    
}

main[data-page="annoncement"] #annonces-filter .filter-zone > [id^="filter-"]:not(:empty) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--twc-gray-300);
}

main[data-page="annoncement"] #annonces-filter .filter-zone > [id^="filter-"]:last-child {
    border-bottom: none;
}

main[data-page="annoncement"] #annonces-filter .filter-zone #filter-sexe-male,
main[data-page="annoncement"] #annonces-filter .filter-zone #filter-sexe-female,
main[data-page="annoncement"] #annonces-filter .filter-zone #filter-animals,
main[data-page="annoncement"] #annonces-filter .filter-zone #filter-vehicles {
    margin-top: 10px;
}