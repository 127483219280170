main[data-page="annoncement"] #annonces-list .loading-gif {
    width: 30px;
    height: 30px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
    box-shadow: var(--clear-thin-shadow-5);
    position: relative;
    display: block;
    width: 100%;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:hover {
    background-color: var(--twc-gray-400-f8);
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
    margin-top: 8px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
    width: 100%;
    padding: 4px 8px 12px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
    margin-top: 8px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 2px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
    display: none;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic img {
    width: 100%;
    display: block;
    box-sizing: border-box;
    max-height: 160px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 4px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding-bottom: 4px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 4px;
    /* margin-bottom: 4px; */
    margin-bottom: 0;
    text-align: center;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem img {
    width: 24px;
    margin: 0 auto;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem span {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 4px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
    width: 100%;
    display: block;
    box-sizing: border-box;
    border-top: 2px solid var(--twc-gray-800);
}

@media (min-width: 768px) {
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
        display: block;
        width: 100%;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
        margin-top: 8px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
        font-size: 20px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
        margin-top: 10px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 4px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
        display: none;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic img {

    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 4px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding-bottom: 6px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 4px 8px;
        /* margin-bottom: 6px; */
        margin-bottom: 0;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem img {

    }
    
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem span {

    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
        width: 100%;
        display: block;
        box-sizing: border-box;
    }

}

@media (min-width: 864px) {
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
        width: 160px;
        vertical-align: top;
        box-sizing: border-box;
        padding: 8px;
        display: inline-block;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
        width: calc(100% - 160px);
        vertical-align: top;
        box-sizing: border-box;
        padding: 12px;
        display: inline-block;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
        width: 25%;
        vertical-align: top;
        margin-bottom: 0;
        padding: 8px;
        display: inline-block;
    }
}

@media (min-width: 1200px) {
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
        display: block;
        width: 100%;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
        margin-top: 12px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
        font-size: 22px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
        margin-top: 16px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
        width: 160px;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        padding: 8px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
        display: none;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic img {
        
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
        width: calc(100% - 160px);
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        padding: 12px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty + .annonce-elem-details-descr {
        width: 100%;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding-bottom: 8px;
    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
        width: 25%;
        display: inline-block;
        vertical-align: top;
        padding: 8px;
        /* margin-bottom: 8px; */
        margin-bottom: 0;
    }
    
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem img {

    }
    
    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem span {

    }

    main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
        width: 100%;
        display: block;
        box-sizing: border-box;
    }

}