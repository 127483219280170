:root {
    /* SHADOWS
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

    --clear-shadow-5: rgba(0, 0, 0, 0.05) 2px 2px 6px 4px;
    --clear-thin-shadow-5: rgba(0, 0, 0, 0.05) 2px 2px 6px 2px;
    --clear-shadow-10: rgba(0, 0, 0, 0.1) 2px 2px 6px 4px;
    --clear-thin-shadow-10: rgba(0, 0, 0, 0.1) 2px 2px 6px 2px;
    --clear-shadow-15: rgba(0, 0, 0, 0.15) 2px 2px 6px 4px;
    --clear-thin-shadow-15: rgba(0, 0, 0, 0.15) 2px 2px 6px 2px;
    
    --dark-shadow-5: rgba(0, 0, 0, 0.3) 2px 2px 6px 4px;
    --dark-thin-shadow-5: rgba(0, 0, 0, 0.3) 2px 2px 6px 2px;
    --dark-shadow-10: rgba(0, 0, 0, 0.4) 2px 2px 6px 4px;
    --dark-thin-shadow-10: rgba(0, 0, 0, 0.4) 2px 2px 6px 2px;
    --dark-shadow-15: rgba(0, 0, 0, 0.5) 2px 2px 6px 4px;
    --dark-thin-shadow-15: rgba(0, 0, 0, 0.5) 2px 2px 6px 2px;

    /* DEFINES */
    
    --header-height: 80px;
    --mini-header-height: 50px;
    --fixed-header-height: 40px;

    /* TAILWIND FONT-WEIGHT */

    --thin: 100;
    --extralight: 200;
    --light: 300;
    --normal: 400;
    --medium: 500;
    --semibold: 600;
    --bold: 700;
    --extrabold: 800;

    /* TAILWIND COLORS */
    
    --black-f8: rgba(0, 0, 0, 0.5);
    --black: rgb(0, 0, 0);
    --white-f8: rgba(255, 255, 255, 0.5);
    --white: rgb(255, 255, 255);
    --gray-50: rgb(249, 250, 251);
    --gray-50-f8: rgba(249, 250, 251, 0.5);
    --gray-100: rgb(243, 244, 246);
    --gray-100-f8: rgba(243, 244, 246, 0.5);
    --gray-200: rgb(229, 231, 235);
    --gray-200-f8: rgba(229, 231, 235, 0.5);
    --gray-300: rgb(209, 213, 219);
    --gray-300-f8: rgba(209, 213, 219, 0.5);
    --gray-400: rgb(156, 163, 175);
    --gray-400-f8: rgba(156, 163, 175, 0.5);
    --gray-500: rgb(107, 114, 128);
    --gray-500-f8: rgba(107, 114, 128, 0.5);
    --gray-600: rgb(75, 85, 99);
    --gray-600-f8: rgba(75, 85, 99, 0.5);
    --gray-700: rgb(55, 65, 81);
    --gray-700-f8: rgba(55, 65, 81, 0.5);
    --gray-800: rgb(31, 41, 55);
    --gray-800-f8: rgba(31, 41, 55, 0.5);
    --gray-900: rgb(17, 24, 39);
    --gray-900-f8: rgba(17, 24, 39, 0.5);
    --red-50: rgb(254, 242, 242);
    --red-50-f8: rgba(254, 242, 242, 0.5);
    --red-100: rgb(254, 226, 226);
    --red-100-f8: rgba(254, 226, 226, 0.5);
    --red-200: rgb(254, 202, 202);
    --red-200-f8: rgba(254, 202, 202, 0.5);
    --red-300: rgb(252, 165, 165);
    --red-300-f8: rgba(252, 165, 165, 0.5);
    --red-400: rgb(248, 113, 113);
    --red-400-f8: rgba(248, 113, 113, 0.5);
    --red-500: rgb(239, 68, 68);
    --red-500-f8: rgba(239, 68, 68, 0.5);
    --red-600: rgb(220, 38, 38);
    --red-600-f8: rgba(220, 38, 38, 0.5);
    --red-700: rgb(185, 28, 28);
    --red-700-f8: rgba(185, 28, 28, 0.5);
    --red-800: rgb(153, 27, 27);
    --red-800-f8: rgba(153, 27, 27, 0.5);
    --red-900: rgb(127, 29, 29);
    --red-900-f8: rgba(127, 29, 29, 0.5);
    --yellow-50: rgb(255, 251, 235);
    --yellow-50-f8: rgba(255, 251, 235, 0.5);
    --yellow-100: rgb(254, 243, 199);
    --yellow-100-f8: rgba(254, 243, 199, 0.5);
    --yellow-200: rgb(253, 230, 138);
    --yellow-200-f8: rgba(253, 230, 138, 0.5);
    --yellow-300: rgb(252, 211, 77);
    --yellow-300-f8: rgba(252, 211, 77, 0.5);
    --yellow-400: rgb(251, 191, 36);
    --yellow-400-f8: rgba(251, 191, 36, 0.5);
    --yellow-500: rgb(245, 158, 11);
    --yellow-500-f8: rgba(245, 158, 11, 0.5);
    --yellow-600: rgb(217, 119, 6);
    --yellow-600-f8: rgba(217, 119, 6, 0.5);
    --yellow-700: rgb(180, 83, 9);
    --yellow-700-f8: rgba(180, 83, 9, 0.5);
    --yellow-800: rgb(146, 64, 14);
    --yellow-800-f8: rgba(146, 64, 14, 0.5);
    --yellow-900: rgb(120, 53, 15);
    --yellow-900-f8: rgba(120, 53, 15, 0.5);
    --green-50: rgb(236, 253, 245);
    --green-50-f8: rgba(236, 253, 245, 0.5);
    --green-100: rgb(209, 250, 229);
    --green-100-f8: rgba(209, 250, 229, 0.5);
    --green-200: rgb(167, 243, 208);
    --green-200-f8: rgba(167, 243, 208, 0.5);
    --green-300: rgb(110, 231, 183);
    --green-300-f8: rgba(110, 231, 183, 0.5);
    --green-400: rgb(52, 211, 153);
    --green-400-f8: rgba(52, 211, 153, 0.5);
    --green-500: rgb(16, 185, 129);
    --green-500-f8: rgba(16, 185, 129, 0.5);
    --green-600: rgb(5, 150, 105);
    --green-600-f8: rgba(5, 150, 105, 0.5);
    --green-700: rgb(4, 120, 87);
    --green-700-f8: rgba(4, 120, 87, 0.5);
    --green-800: rgb(6, 95, 70);
    --green-800-f8: rgba(6, 95, 70, 0.5);
    --green-900: rgb(6, 78, 59);
    --green-900-f8: rgba(6, 78, 59, 0.5);
    --blue-50: rgb(239, 246, 255);
    --blue-50-f8: rgba(239, 246, 255, 0.5);
    --blue-100: rgb(219, 234, 254);
    --blue-100-f8: rgba(219, 234, 254, 0.5);
    --blue-200: rgb(191, 219, 254);
    --blue-200-f8: rgba(191, 219, 254, 0.5);
    --blue-300: rgb(147, 197, 253);
    --blue-300-f8: rgba(147, 197, 253, 0.5);
    --blue-400: rgb(96, 165, 250);
    --blue-400-f8: rgba(96, 165, 250, 0.5);
    --blue-500: rgb(59, 130, 246);
    --blue-500-f8: rgba(59, 130, 246, 0.5);
    --blue-600: rgb(37, 99, 235);
    --blue-600-f8: rgba(37, 99, 235, 0.5);
    --blue-700: rgb(29, 78, 216);
    --blue-700-f8: rgba(29, 78, 216, 0.5);
    --blue-800: rgb(30, 64, 175);
    --blue-800-f8: rgba(30, 64, 175, 0.5);
    --blue-900: rgb(30, 58, 138);
    --blue-900-f8: rgba(30, 58, 138, 0.5);
    --indigo-50: rgb(238, 242, 255);
    --indigo-50-f8: rgba(238, 242, 255, 0.5);
    --indigo-100: rgb(224, 231, 255);
    --indigo-100-f8: rgba(224, 231, 255, 0.5);
    --indigo-200: rgb(199, 210, 254);
    --indigo-200-f8: rgba(199, 210, 254, 0.5);
    --indigo-300: rgb(165, 180, 252);
    --indigo-300-f8: rgba(165, 180, 252, 0.5);
    --indigo-400: rgb(129, 140, 248);
    --indigo-400-f8: rgba(129, 140, 248, 0.5);
    --indigo-500: rgb(99, 102, 241);
    --indigo-500-f8: rgba(99, 102, 241, 0.5);
    --indigo-600: rgb(79, 70, 229);
    --indigo-600-f8: rgba(79, 70, 229, 0.5);
    --indigo-700: rgb(67, 56, 202);
    --indigo-700-f8: rgba(67, 56, 202, 0.5);
    --indigo-800: rgb(55, 48, 163);
    --indigo-800-f8: rgba(55, 48, 163, 0.5);
    --indigo-900: rgb(49, 46, 129);
    --indigo-900-f8: rgba(49, 46, 129, 0.5);
    --purple-50: rgb(245, 243, 255);
    --purple-50-f8: rgba(245, 243, 255, 0.5);
    --purple-100: rgb(237, 233, 254);
    --purple-100-f8: rgba(237, 233, 254, 0.5);
    --purple-200: rgb(221, 214, 254);
    --purple-200-f8: rgba(221, 214, 254, 0.5);
    --purple-300: rgb(196, 181, 253);
    --purple-300-f8: rgba(196, 181, 253, 0.5);
    --purple-400: rgb(167, 139, 250);
    --purple-400-f8: rgba(167, 139, 250, 0.5);
    --purple-500: rgb(139, 92, 246);
    --purple-500-f8: rgba(139, 92, 246, 0.5);
    --purple-600: rgb(124, 58, 237);
    --purple-600-f8: rgba(124, 58, 237, 0.5);
    --purple-700: rgb(109, 40, 217);
    --purple-700-f8: rgba(109, 40, 217, 0.5);
    --purple-800: rgb(91, 33, 182);
    --purple-800-f8: rgba(91, 33, 182, 0.5);
    --purple-900: rgb(76, 29, 149);
    --purple-900-f8: rgba(76, 29, 149, 0.5);
    --pink-50: rgb(253, 242, 248);
    --pink-50-f8: rgba(253, 242, 248, 0.5);
    --pink-100: rgb(252, 231, 243);
    --pink-100-f8: rgba(252, 231, 243, 0.5);
    --pink-200: rgb(251, 207, 232);
    --pink-200-f8: rgba(251, 207, 232, 0.5);
    --pink-300: rgb(249, 168, 212);
    --pink-300-f8: rgba(249, 168, 212, 0.5);
    --pink-400: rgb(244, 114, 182);
    --pink-400-f8: rgba(244, 114, 182, 0.5);
    --pink-500: rgb(236, 72, 153);
    --pink-500-f8: rgba(236, 72, 153, 0.5);
    --pink-600: rgb(219, 39, 119);
    --pink-600-f8: rgba(219, 39, 119, 0.5);
    --pink-700: rgb(190, 24, 93);
    --pink-700-f8: rgba(190, 24, 93, 0.5);
    --pink-800: rgb(157, 23, 77);
    --pink-800-f8: rgba(157, 23, 77, 0.5);
    --pink-900: rgb(131, 24, 67);
    --pink-900-f8: rgba(131, 24, 67, 0.5);
}