#annonce-details #annonce-faq {

}

@media (max-width: 1199px) {
    #annonce-details #annonce-faq {
        width: 60%;
        padding: 20px;
    }
}

@media (max-width: 767px) {
    #annonce-details #annonce-faq {
        padding: 15px;
    }
}

@media (max-width: 424px) {
    #annonce-details #annonce-faq {
        display: block;
        width: 100%;
        padding: 10px;
    }
}

@media (min-width: 768px) {
    #annonce-details #annonce-faq {
        display: inline-block;
        vertical-align: top;
    }
}

@media (min-width: 1200px) {
    #annonce-details #annonce-faq {
        width: 80%;
        padding: 25px;
    }
}
