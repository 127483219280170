@media (min-width: 0px) {
    main {
        padding: 5px 5px 10px;
    }
}

@media (min-width: 768px) {
    main {
        padding: 10px 10px 15px;
    }

    main[data-page="annoncement"] {
        display: flex;
        column-gap: 2%;
    }
}

@media (min-width: 1199px) {
    main {
        padding: 15px 15px 25px;
    }
}

@media (min-width: 1200px) {
    main {
        padding: 25px 25px 50px;
    }
}

main[data-page="404"] h2 {
    font-size: 48px;
    width: 90%;
    max-width: 960px;
    color: var(--twc-black);
    margin: 32px auto;
    font-weight: bold;
    text-align: center;
}

main[data-page="annonce"] {
    padding: 0;
    position :relative;
}

main[data-page="annonce"] #annonce-load {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

main[data-page="annonce"] #annonce-change {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

main[data-page="annonce"] #annonce-details {
    position: relative;
    z-index: 0;
}

@media (max-width: 424px) {
    main[data-page="annonce"] #annonce-details {
        margin-top: 100px;
        max-height: calc(100% - 100px);
    }
}

@media (min-width: 425px) {
    main[data-page="annonce"] #annonce-details {
        margin-top: 100px;
        max-height: calc(100% - 100px);
    }
}

@media (min-width: 768px) {
    main[data-page="annonce"] #annonce-details {
        margin-top: 64px;
        max-height: calc(100% - 64px);
    }
}

@media (min-width: 1200px) {
    main[data-page="annonce"] #annonce-details {
        margin-top: 64px;
        max-height: calc(100% - 64px);
    }
}
