main[data-page="annoncement"] #annonces-list {
    /* box-shadow: var(--clear-thin-shadow-5); */
    position: relative;
    padding: 10px 4px;
}

@media (min-width: 0px) {
    main[data-page="annoncement"] #annonces-list {
        display: block;
        width: 100%;
        padding: 10px 4px;
    }
}

@media (min-width: 768px) {
    main[data-page="annoncement"] #annonces-list {
        flex: 6;
        padding: 10px 4px;
    }
}

@media (min-width: 1199px) {
    main[data-page="annoncement"] #annonces-list {
        flex: 6;
        padding: 15px 8px;
    }
}

@media (min-width: 1200px) {
    main[data-page="annoncement"] #annonces-list {
        flex: 6;
        padding: 25px 12px;
    }
}
