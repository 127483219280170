@import url("modales/base.css");

/* MODALE USER DATAS 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

#modale #modale-container #modale-content #modale-user_datas {

}

:root {
    --modale-lang-item-height: 30px;
}

/* SET LANGUAGE */

ul#modale-set-lng {
    width: 8rem;
    height: var(--modale-lang-item-height);
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    border-top-right-radius: 0.5rem;
    transition: height 0.3s ease-in-out 0s;
    z-index: 1;
    border-left: 2px solid var(--twc-pink-900);
    border-bottom: 2px solid var(--twc-pink-900);
}

ul#modale-set-lng:hover {
    height: calc(var(--modale-lang-item-height) * attr(data-nb_langs));
}

ul#modale-set-lng .dropdown-menu,
ul#modale-set-lng .dropdown-item {
    height: var(--modale-lang-item-height);
}

ul#modale-set-lng .dropdown-item {
    font-size: 10px;
}

ul#modale-set-lng .current-lng {
    color: var(--twc-white);
    font-weight: bold;
    background-color: var(--twc-pink-900);
}

@media only screen and (max-width: 1199px) {
    ul#modale-set-lng {
        top: 0;
        height: var(--mini-header-height);
    }

    ul#modale-set-lng .dropdown-menu,
    ul#modale-set-lng .dropdown-item {
        height: var(--mini-header-height);
        line-height: calc(var(--mini-header-height) - 1rem);
    }
}
