main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {

}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-poster {
    display: none;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos,
main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals,
main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-project_poster,
main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project {
    box-shadow: var(--clear-thin-shadow-5);
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos:not(:first-child),
main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals:not(:first-child),
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-project_poster:not(:first-child),
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project:not(:first-child) {
    margin-top: 16px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos {
    
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details {

}


main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem img {
    width: 24px;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    position: relative;
    top: 2px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem span {
    width: calc(100% - 32px);
    margin-left: 8px;
    display: inline-block;
    vertical-align: top;
    white-space: pre-wrap;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals {
    
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details {
    padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 6px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details h3:not(:first-child) {
    margin-top: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details div {
    margin-top: 4px;
    margin-left: 4px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details .annonce-infos-practicals-details-detail {

}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project {

}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 6px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project #annonce-infos-project_poster {
    display: none;
}

@media (max-width: 1199px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
        width: 40%;
        padding: 10px;
    }
}

@media (max-width: 767px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
        display: block;
        width: 100%;
        padding: 5px;
    }

    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }
}

@media (max-width: 424px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {

    }

    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
        display: block;
        width: 100%;
    }
}

@media (min-width: 768px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
        display: inline-block;
        vertical-align: top;
    }

    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-poster {
        display: block;
        width: 100%;
    }

    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project #annonce-infos-project_poster {
        display: block;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
        width: 20%;
        padding: 10px;
    }
}