@import url("const.css");
@import url("reset.css");
@import url("modale.css");

:root {
    --header-height: 5rem;
}

/* Toggle A */
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #831843;
}
  
/* Toggle B */
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #831843;
}

/* #OVERFLOW-SCROLL TABLE 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

table {
    /* overflow-x: scroll; */
}
thead {
    width: 100%;
}
thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
tbody {
    display: block;
    overflow-y: auto;
    /* overflow-x: hidden; */
}
tr {
    white-space: nowrap;
}
th,
td {
    width: 100%;
    max-width: 250px;
    /* display: inline-block; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
th {
    white-space: nowrap;
}
td {
    position: relative;
}
tr:nth-child(even) {background:#ffffff}
tr:nth-child(even) td {background:#ffffff}
tr:nth-child(odd) {background:#f1f5fb}
tr:nth-child(odd) td {background:#f1f5fb}

/* resizable tables */

.table th {
    position: relative;
}

.resizer {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}
th:hover .resizer,
.resizer:hover,
.resizing {
    /* border-right: 2px solid blue; */
    background-color: blue;
}
th.jaj-calendar .resizer {
    left: 0;
}

/* CUSTOM SCROLLBARS 
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.custom-scrollbar {
    scrollbar-width: auto;
    scrollbar-color: #BE185D #F3F4F6;
}

.thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #BE185D #F3F4F6;
}

.no-scrollbar {
    scrollbar-width: none;
    scrollbar-color: #BE185D #F3F4F6;
}

.custom-scrollbar::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar-button,
.custom-scrollbar::-webkit-scrollbar-thumb {
    width: 7px;
    height: 7px;
}
.thin-scrollbar::-webkit-scrollbar,
.thin-scrollbar::-webkit-scrollbar-button,
.thin-scrollbar::-webkit-scrollbar-thumb {
    width: 3px;
    height: 3px;
}
.no-scrollbar::-webkit-scrollbar,
.no-scrollbar::-webkit-scrollbar-button,
.no-scrollbar::-webkit-scrollbar-thumb {
    height: 0px;
    width: 0px;
}

.custom-scrollbar,
.thin-scrollbar,
.no-scrollbar {
    overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar,
.thin-scrollbar::-webkit-scrollbar,
.no-scrollbar::-webkit-scrollbar {
    background-color: #F3F4F6;
}
.custom-scrollbar::-webkit-scrollbar-button,
.thin-scrollbar::-webkit-scrollbar-button,
.no-scrollbar::-webkit-scrollbar-button {
    background-color: #831843;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.thin-scrollbar::-webkit-scrollbar-thumb,
.no-scrollbar::-webkit-scrollbar-thumb {
    background-color: #BE185D;
}


.custom-scrollbar.black-scrollbar::-webkit-scrollbar,
.thin-scrollbar.black-scrollbar::-webkit-scrollbar,
.no-scrollbar.black-scrollbar::-webkit-scrollbar {
    background-color: #F3F4F6;
}
.custom-scrollbar.black-scrollbar::-webkit-scrollbar-button,
.thin-scrollbar.black-scrollbar::-webkit-scrollbar-button,
.no-scrollbar.black-scrollbar::-webkit-scrollbar-button {
    background-color: #000;
}
.custom-scrollbar.black-scrollbar::-webkit-scrollbar-thumb,
.thin-scrollbar.black-scrollbar::-webkit-scrollbar-thumb,
.no-scrollba.black-scrollbarr::-webkit-scrollbar-thumb {
    background-color: #111;
}

/* #ABSOLUTE-LINK
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
a.absolute-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
}

/* #BACKGROUND-IMAGE
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.background-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* #ELLISPSIS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* #BODY WRAPPER
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
#wrapper {
    width: calc(100% - 3.5rem);
}
#wrapper.unlog,
#wrapper[data-size="full"] {
    width: 100%;
}

/* USER SELECT NONE
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.user-select-none {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
}


/* LISTtodo BORDER COLOR  
  -------------------------------------------------- */
.border-modere {
    border-right: 5px solid orange;
}
.border-urgent {
    border-right: 5px solid red;
}
.border-tranquille {
    border-right: 5px solid green;
}

.hover-text-bold:hover {
    font-weight: 600;
}

/* hover navlink view project
  -------------------------------------------------- */

.header-project-create {
    
}

.header-project-create:hover {
    transition: background 0.2s ease-in-out 0s;
}

.header-project-link {
    padding: 4px 12px;
    border-radius: 0.25rem;
    transition: background 0.2s ease-in-out 0s,
                color 0.2s ease-in-out 0s,
                font-weight 0.2s ease-in-out 0s;
}

.header-project-link:hover {
    background-color: var(--twc-black);
    color: var(--twc-white);
}

/* form consts
  -------------------------------------------------- */

form label.required:not(.hide-star)::after {
    content: '*';
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #831843;
    margin-left: 2px;
}

form .hide-star label.required::after,
form label.required.hide-star::after {
    content: "";
}

/* TEXT SWITCH 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.show-mode div.active,
.show-mode div.inactive,
.show-mode input.active,
.show-mode input.inactive,
.show-mode textarea.active,
.show-mode textarea.inactive,
.edit-mode div.active,
.edit-mode div.inactive,
.edit-mode input.active,
.edit-mode input.inactive,
.edit-mode textarea.active,
.edit-mode textarea.inactive {

}

.show-mode div.inactive,
.show-mode input.inactive,
.show-mode textarea.inactive,
.edit-mode div.inactive,
.edit-mode input.inactive,
.edit-mode textarea.inactive {
    display: none;
}

.show-mode input,
.show-mode textarea,
.edit-mode input,
.edit-mode textarea {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: Roboto, sans-serif;
}

.edit-mode textarea {
    resize: none;
}

.show-mode div.active,
.show-mode input.active,
.show-mode textarea.active,
.edit-mode div.active,
.edit-mode input.active,
.edit-mode textarea.active {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.show-mode div.active {

}

.show-mode div.inactive {

}

.show-mode input.active {

}

.show-mode input.inactive {

}

.show-mode textarea.active {

}

.show-mode textarea.inactive {

}

.edit-mode div.active {

}

.edit-mode div.inactive {

}

.edit-mode input.active {
    color: black;
    font-weight: 600;
    padding: 0 0.125rem;
    z-index: 1;
    position: relative;
    background: white;
    box-shadow: #1118 1px 1px 4px 2px;
}

.edit-mode input.inactive {

}

.edit-mode textarea.active {
    color: black;
    font-weight: 600;
    padding: 0 0.125rem;
}

.edit-mode textarea.inactive {

}

/* PAGE LOADER 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

#page-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 999;
    background-color: rgba(31, 31, 31, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

#page-loader img {
    width: 20rem;
}

/* LOADING GIF 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.loading-gif {
    position: relative;
}

.loading-gif {
    margin: 0 auto;
}

.loading-gif .loader-circle,
.loading-gif .loader-bar {
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
}

.loading-gif.extra-small,
.loading-gif.extra-small .loader-circle,
.loading-gif.extra-small .loader-bar {
    width: 10px;
    height: 10px;
}

.loading-gif.mini,
.loading-gif.mini .loader-circle,
.loading-gif.mini .loader-bar {
    width: 20px;
    height: 20px;
}

.loading-gif,
.loading-gif .loader-circle,
.loading-gif .loader-bar {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.loading-gif.large,
.loading-gif.large .loader-circle,
.loading-gif.large .loader-bar {
    width: 40px;
    height: 40px;
}

.loading-gif.extra-large,
.loading-gif.extra-large .loader-circle,
.loading-gif.extra-large .loader-bar {
    width: 60px;
    height: 60px;
}

.loading-gif .loader-circle {
    border: 5px solid #f3f3f3;
}

.loading-gif .loader-bar {
    border: 5px solid transparent;
    border-top: 5px solid #831843;
    -webkit-animation: spin 1.5s cubic-bezier(0, 0.07, 0.8, 0.88) infinite; /* Safari */
    animation: spin 1.5s cubic-bezier(0, 0.07, 0.8, 0.88) infinite;
}
  
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* MASKS 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--twc-gray-300-f8);
    z-index: 999;
}

.loading-mask .loading-gif {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.mask-error {
    background-image: linear-gradient(45deg, #8004, #8002, #8001);
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: #f00a;
}

.mask-success {
    background-image: linear-gradient(45deg, #0803, #0802, #0801);
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: #0f0A;
}

/* TOGGLE OPENERS 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.expand-view,
.retract-view {
    cursor: pointer;
}

.retract-view {
    transform: scaleY(-1);
}

/* CHEEKS 
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

/* lock */

.cheeks-lock {
    width: 50px;
    height: 60px;
    position: relative;
}

.cheeks-lock:not(.opened)::before,
.cheeks-lock:not(.opened)::after {
    border-color: red;
}

.cheeks-lock.opened::before,
.cheeks-lock.opened::after {
    border-color: green;
}

.cheeks-lock::before {
    content: "";
    width: 60%;
    height: 50%;
    border: 2px solid;
    border-bottom: none;
    border-radius: 30% 30% 0 0;
    position: absolute;
    top: 3.5%;
    left: 20%;
    transition: transform 0.3s cubic-bezier(0.33,-0.59, 0.27, 1.15) 0s, height 0.3s ease-in-out 0s, border 0.3s ease-in-out 0s;
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    box-sizing: border-box;
}

.cheeks-lock.opened::before {
    height: 40%;
    transition: transform 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, border 0.3s ease-in-out 0s;
    transform: translate3d(-10%, -8%, 0px) rotateZ(-20deg);
}

.cheeks-lock::after {
    content: "";
    position: absolute;
    bottom: 0;
    border: 2px solid;
    width: 100%;
    height: 60%;
    left: 0;
    background: white;
    box-sizing: border-box;
    transition: border 0.3s ease-in-out 0s;
}
