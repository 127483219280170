main[data-page="annonce"] #annonce-bandeau {
    width: 100%;
    background-color: var(--twc-white);
    box-shadow: var(--clear-thin-shadow-5);
    padding: 8px;
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: 1;
}

main[data-page="annonce"] #annonce-bandeau .annonce-title {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: var(--twc-black);
    white-space: nowrap;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat,
main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
    width: 49%;
    display: inline-block;
    height: 32px;
    text-align: center;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
    margin-right: 2%;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat.saving span {
    display: none;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat #annonce-bandeau-candidat-waiter {
    width: 16px;
    height: 16px;
    display: none;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat.saving #annonce-bandeau-candidat-waiter {
    display: block;
}

@media only screen and (max-width: 1199px) {
    main[data-page="annonce"] #annonce-bandeau {
        top: var(--mini-header-height);
    }
}

@media (max-width: 767px) {
    main[data-page="annonce"] #annonce-bandeau {
        box-shadow: var(--clear-thin-shadow-10);
    }
    main[data-page="annonce"] #annonce-bandeau .annonce-title {
        margin: 12px auto 14px;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat,
    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
        width: calc(50% - 16px);
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
        margin-right: 16px;
    }
}

@media (max-width: 424px) {
    main[data-page="annonce"] #annonce-bandeau .annonce-title {
        margin: 8px auto;
    }
}

@media (min-width: 425px) {
    main[data-page="annonce"] #annonce-bandeau {
        padding: 12px;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-title {
        position: relative;
        width: 100%;
        display: block;
    }
}

@media (min-width: 768px) {
    main[data-page="annonce"] #annonce-bandeau {
        padding: 12px;
        height: 64px;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-title {
        position: absolute;
        top: 22px;
        left: 10px;
        max-width: calc(100% - 448px);
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat,
    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
        position: absolute;
        top: 16px;
        width: 200px;
        height: 32px;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
        right: 212px;
    }
    
    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
        right: 16px;
    }
}

@media (min-width: 1200px) {
    main[data-page="annonce"] #annonce-bandeau {
        height: 64px;
        padding: 16px;
    }

    main[data-page="annonce"] #annonce-bandeau .annonce-title {

    }

    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat,
    main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
        
    }
}