:root {
    /* SHADOWS
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

    --clear-shadow-5: rgba(0, 0, 0, 0.05) 2px 2px 6px 4px;
    --clear-thin-shadow-5: rgba(0, 0, 0, 0.05) 2px 2px 6px 2px;
    --clear-shadow-10: rgba(0, 0, 0, 0.1) 2px 2px 6px 4px;
    --clear-thin-shadow-10: rgba(0, 0, 0, 0.1) 2px 2px 6px 2px;
    --clear-shadow-15: rgba(0, 0, 0, 0.15) 2px 2px 6px 4px;
    --clear-thin-shadow-15: rgba(0, 0, 0, 0.15) 2px 2px 6px 2px;
    
    --dark-shadow-5: rgba(0, 0, 0, 0.3) 2px 2px 6px 4px;
    --dark-thin-shadow-5: rgba(0, 0, 0, 0.3) 2px 2px 6px 2px;
    --dark-shadow-10: rgba(0, 0, 0, 0.4) 2px 2px 6px 4px;
    --dark-thin-shadow-10: rgba(0, 0, 0, 0.4) 2px 2px 6px 2px;
    --dark-shadow-15: rgba(0, 0, 0, 0.5) 2px 2px 6px 4px;
    --dark-thin-shadow-15: rgba(0, 0, 0, 0.5) 2px 2px 6px 2px;

    /* BOOKING MANAGER */
    --casting-block: #fbbf24;
    /* --casting-block: #d12626; */
    --essayage-block: #34d399;
    /* --essayage-block: #8f8f05; */
    --tournage-block: #f87171;
    /* --tournage-block: #c75a12; */
    --repetition-block: #60a5fa;
    /* --repetition-block: #60a5fa; */
    --photo-block: #8b5cf6;
    /* --photo-block: #40e0d0; */
    --unk-block: #d1d5db;
    /* --unk-block: #d1d5db; */

    --doublure-block: #f87171;
    /* --doublure-block: #f7ae12; */
    --silhouette-block: #f87171;
    /* --silhouette-block: #ffff00; */
    
    --casting-block-darken: hsl(43, 96%, 42%);
    --essayage-block-darken: hsl(158, 64%, 39%);
    --repetition-block-darken: hsl(213, 94%, 51%);
    --tournage-block-darken: hsl(0, 91%, 48.25%);
    --photo-block-darken: hsl(258, 90%, 49.5%);
    --unk-block-darken: hsl(216, 12%, 63%);

    /* ATTRIBUTION MANAGER */

    /* TAILWIND COLORS */
    
    --twc-black-f8: rgba(0, 0, 0, 0.5);
    --twc-black: rgb(0, 0, 0);
    --twc-white-f8: rgba(255, 255, 255, 0.5);
    --twc-white: rgb(255, 255, 255);
    --twc-gray-50: rgb(249, 250, 251);
    --twc-gray-50-f8: rgba(249, 250, 251, 0.5);
    --twc-gray-100: rgb(243, 244, 246);
    --twc-gray-100-f8: rgba(243, 244, 246, 0.5);
    --twc-gray-200: rgb(229, 231, 235);
    --twc-gray-200-f8: rgba(229, 231, 235, 0.5);
    --twc-gray-300: rgb(209, 213, 219);
    --twc-gray-300-f8: rgba(209, 213, 219, 0.5);
    --twc-gray-400: rgb(156, 163, 175);
    --twc-gray-400-f8: rgba(156, 163, 175, 0.5);
    --twc-gray-500: rgb(107, 114, 128);
    --twc-gray-500-f8: rgba(107, 114, 128, 0.5);
    --twc-gray-600: rgb(75, 85, 99);
    --twc-gray-600-f8: rgba(75, 85, 99, 0.5);
    --twc-gray-700: rgb(55, 65, 81);
    --twc-gray-700-f8: rgba(55, 65, 81, 0.5);
    --twc-gray-800: rgb(31, 41, 55);
    --twc-gray-800-f8: rgba(31, 41, 55, 0.5);
    --twc-gray-900: rgb(17, 24, 39);
    --twc-gray-900-f8: rgba(17, 24, 39, 0.5);
    --twc-red-50: rgb(254, 242, 242);
    --twc-red-50-f8: rgba(254, 242, 242, 0.5);
    --twc-red-100: rgb(254, 226, 226);
    --twc-red-100-f8: rgba(254, 226, 226, 0.5);
    --twc-red-200: rgb(254, 202, 202);
    --twc-red-200-f8: rgba(254, 202, 202, 0.5);
    --twc-red-300: rgb(252, 165, 165);
    --twc-red-300-f8: rgba(252, 165, 165, 0.5);
    --twc-red-400: rgb(248, 113, 113);
    --twc-red-400-f8: rgba(248, 113, 113, 0.5);
    --twc-red-500: rgb(239, 68, 68);
    --twc-red-500-f8: rgba(239, 68, 68, 0.5);
    --twc-red-600: rgb(220, 38, 38);
    --twc-red-600-f8: rgba(220, 38, 38, 0.5);
    --twc-red-700: rgb(185, 28, 28);
    --twc-red-700-f8: rgba(185, 28, 28, 0.5);
    --twc-red-800: rgb(153, 27, 27);
    --twc-red-800-f8: rgba(153, 27, 27, 0.5);
    --twc-red-900: rgb(127, 29, 29);
    --twc-red-900-f8: rgba(127, 29, 29, 0.5);
    --twc-yellow-50: rgb(255, 251, 235);
    --twc-yellow-50-f8: rgba(255, 251, 235, 0.5);
    --twc-yellow-100: rgb(254, 243, 199);
    --twc-yellow-100-f8: rgba(254, 243, 199, 0.5);
    --twc-yellow-200: rgb(253, 230, 138);
    --twc-yellow-200-f8: rgba(253, 230, 138, 0.5);
    --twc-yellow-300: rgb(252, 211, 77);
    --twc-yellow-300-f8: rgba(252, 211, 77, 0.5);
    --twc-yellow-400: rgb(251, 191, 36);
    --twc-yellow-400-f8: rgba(251, 191, 36, 0.5);
    --twc-yellow-500: rgb(245, 158, 11);
    --twc-yellow-500-f8: rgba(245, 158, 11, 0.5);
    --twc-yellow-600: rgb(217, 119, 6);
    --twc-yellow-600-f8: rgba(217, 119, 6, 0.5);
    --twc-yellow-700: rgb(180, 83, 9);
    --twc-yellow-700-f8: rgba(180, 83, 9, 0.5);
    --twc-yellow-800: rgb(146, 64, 14);
    --twc-yellow-800-f8: rgba(146, 64, 14, 0.5);
    --twc-yellow-900: rgb(120, 53, 15);
    --twc-yellow-900-f8: rgba(120, 53, 15, 0.5);
    --twc-green-50: rgb(236, 253, 245);
    --twc-green-50-f8: rgba(236, 253, 245, 0.5);
    --twc-green-100: rgb(209, 250, 229);
    --twc-green-100-f8: rgba(209, 250, 229, 0.5);
    --twc-green-200: rgb(167, 243, 208);
    --twc-green-200-f8: rgba(167, 243, 208, 0.5);
    --twc-green-300: rgb(110, 231, 183);
    --twc-green-300-f8: rgba(110, 231, 183, 0.5);
    --twc-green-400: rgb(52, 211, 153);
    --twc-green-400-f8: rgba(52, 211, 153, 0.5);
    --twc-green-500: rgb(16, 185, 129);
    --twc-green-500-f8: rgba(16, 185, 129, 0.5);
    --twc-green-600: rgb(5, 150, 105);
    --twc-green-600-f8: rgba(5, 150, 105, 0.5);
    --twc-green-700: rgb(4, 120, 87);
    --twc-green-700-f8: rgba(4, 120, 87, 0.5);
    --twc-green-800: rgb(6, 95, 70);
    --twc-green-800-f8: rgba(6, 95, 70, 0.5);
    --twc-green-900: rgb(6, 78, 59);
    --twc-green-900-f8: rgba(6, 78, 59, 0.5);
    --twc-blue-50: rgb(239, 246, 255);
    --twc-blue-50-f8: rgba(239, 246, 255, 0.5);
    --twc-blue-100: rgb(219, 234, 254);
    --twc-blue-100-f8: rgba(219, 234, 254, 0.5);
    --twc-blue-200: rgb(191, 219, 254);
    --twc-blue-200-f8: rgba(191, 219, 254, 0.5);
    --twc-blue-300: rgb(147, 197, 253);
    --twc-blue-300-f8: rgba(147, 197, 253, 0.5);
    --twc-blue-400: rgb(96, 165, 250);
    --twc-blue-400-f8: rgba(96, 165, 250, 0.5);
    --twc-blue-500: rgb(59, 130, 246);
    --twc-blue-500-f8: rgba(59, 130, 246, 0.5);
    --twc-blue-600: rgb(37, 99, 235);
    --twc-blue-600-f8: rgba(37, 99, 235, 0.5);
    --twc-blue-700: rgb(29, 78, 216);
    --twc-blue-700-f8: rgba(29, 78, 216, 0.5);
    --twc-blue-800: rgb(30, 64, 175);
    --twc-blue-800-f8: rgba(30, 64, 175, 0.5);
    --twc-blue-900: rgb(30, 58, 138);
    --twc-blue-900-f8: rgba(30, 58, 138, 0.5);
    --twc-indigo-50: rgb(238, 242, 255);
    --twc-indigo-50-f8: rgba(238, 242, 255, 0.5);
    --twc-indigo-100: rgb(224, 231, 255);
    --twc-indigo-100-f8: rgba(224, 231, 255, 0.5);
    --twc-indigo-200: rgb(199, 210, 254);
    --twc-indigo-200-f8: rgba(199, 210, 254, 0.5);
    --twc-indigo-300: rgb(165, 180, 252);
    --twc-indigo-300-f8: rgba(165, 180, 252, 0.5);
    --twc-indigo-400: rgb(129, 140, 248);
    --twc-indigo-400-f8: rgba(129, 140, 248, 0.5);
    --twc-indigo-500: rgb(99, 102, 241);
    --twc-indigo-500-f8: rgba(99, 102, 241, 0.5);
    --twc-indigo-600: rgb(79, 70, 229);
    --twc-indigo-600-f8: rgba(79, 70, 229, 0.5);
    --twc-indigo-700: rgb(67, 56, 202);
    --twc-indigo-700-f8: rgba(67, 56, 202, 0.5);
    --twc-indigo-800: rgb(55, 48, 163);
    --twc-indigo-800-f8: rgba(55, 48, 163, 0.5);
    --twc-indigo-900: rgb(49, 46, 129);
    --twc-indigo-900-f8: rgba(49, 46, 129, 0.5);
    --twc-purple-50: rgb(245, 243, 255);
    --twc-purple-50-f8: rgba(245, 243, 255, 0.5);
    --twc-purple-100: rgb(237, 233, 254);
    --twc-purple-100-f8: rgba(237, 233, 254, 0.5);
    --twc-purple-200: rgb(221, 214, 254);
    --twc-purple-200-f8: rgba(221, 214, 254, 0.5);
    --twc-purple-300: rgb(196, 181, 253);
    --twc-purple-300-f8: rgba(196, 181, 253, 0.5);
    --twc-purple-400: rgb(167, 139, 250);
    --twc-purple-400-f8: rgba(167, 139, 250, 0.5);
    --twc-purple-500: rgb(139, 92, 246);
    --twc-purple-500-f8: rgba(139, 92, 246, 0.5);
    --twc-purple-600: rgb(124, 58, 237);
    --twc-purple-600-f8: rgba(124, 58, 237, 0.5);
    --twc-purple-700: rgb(109, 40, 217);
    --twc-purple-700-f8: rgba(109, 40, 217, 0.5);
    --twc-purple-800: rgb(91, 33, 182);
    --twc-purple-800-f8: rgba(91, 33, 182, 0.5);
    --twc-purple-900: rgb(76, 29, 149);
    --twc-purple-900-f8: rgba(76, 29, 149, 0.5);
    --twc-pink-50: rgb(253, 242, 248);
    --twc-pink-50-f8: rgba(253, 242, 248, 0.5);
    --twc-pink-100: rgb(252, 231, 243);
    --twc-pink-100-f8: rgba(252, 231, 243, 0.5);
    --twc-pink-200: rgb(251, 207, 232);
    --twc-pink-200-f8: rgba(251, 207, 232, 0.5);
    --twc-pink-300: rgb(249, 168, 212);
    --twc-pink-300-f8: rgba(249, 168, 212, 0.5);
    --twc-pink-400: rgb(244, 114, 182);
    --twc-pink-400-f8: rgba(244, 114, 182, 0.5);
    --twc-pink-500: rgb(236, 72, 153);
    --twc-pink-500-f8: rgba(236, 72, 153, 0.5);
    --twc-pink-600: rgb(219, 39, 119);
    --twc-pink-600-f8: rgba(219, 39, 119, 0.5);
    --twc-pink-700: rgb(190, 24, 93);
    --twc-pink-700-f8: rgba(190, 24, 93, 0.5);
    --twc-pink-800: rgb(157, 23, 77);
    --twc-pink-800-f8: rgba(157, 23, 77, 0.5);
    --twc-pink-900: rgb(131, 24, 67);
    --twc-pink-900-f8: rgba(131, 24, 67, 0.5);
}