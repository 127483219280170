:root {
    --lang-item-height: 40px;
}

header {
    position: relative;
    height: var(--header-height);
    z-index: 1;
    box-shadow: var(--clear-thin-shadow-5);
}

@media only screen and (max-width: 1199px) {
    header {
        height: var(--mini-header-height);
    }
}

header nav#header-nav {

}

header nav#header-nav a {
    display: inline-block;
    height: 1.5rem;
}

header nav#header-nav a#header-logo img {
    height: 100%;
}

header nav#header-nav a:not(#header-logo) {
    padding: 0 15px;
}

/* SET LANGUAGE */

header ul#header-set-lng {
    width: 10rem;
    height: var(--lang-item-height);
    position: fixed;
    top: 1rem;
    right: 0;
    transition: height 0.3s ease-in-out 0s;
}

header ul#header-set-lng:hover {
    height: calc(var(--lang-item-height) * attr(data-nb_langs));
}

header ul#header-set-lng .dropdown-menu,
header ul#header-set-lng .dropdown-item {
    height: var(--lang-item-height);
}

header ul#header-set-lng .current-lng {
    color: var(--twc-white);
    font-weight: bold;
    background-color: var(--twc-pink-900);
}

@media only screen and (max-width: 1199px) {
    header ul#header-set-lng {
        top: 0;
        height: var(--mini-header-height);
    }

    header ul#header-set-lng .dropdown-menu,
    header ul#header-set-lng .dropdown-item {
        height: var(--mini-header-height);
        line-height: calc(var(--mini-header-height) - 1rem);
    }
}
