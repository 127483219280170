/* INPUT NUMBER */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* UP - Style commun pour tous les range */
input[type="range"] {
  accent-color: var(--twc-pink-700);
}

input[type="range"] + datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
}

input[type="range"] + datalist option {
  text-align: center;
  padding: 0 0 0 5px;
  margin: 0;
  border: none;
  background: none;
  color: var(--twc-black);
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  writing-mode: horizontal-tb;
  transform: rotate(0deg);
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}

input[type="range"] + datalist option:hover {
  color: var(--twc-pink-700);
}

input[type="range"]:hover {
  cursor: pointer;
  accent-color: var(--twc-pink-800);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-moz-range-track,
input[type="range"]::-ms-track {
  accent-color: var(--twc-pink-500);
}

input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
  accent-color: var(--twc-pink-700);
}