.modale-content[data-modale="signup"] {}
.modale-content[data-modale="signin"] {}

@media (min-width: 1536px) {
    .modale-content[data-modale="signup"] {}
    .modale-content[data-modale="signin"] {}
}

@media (min-width: 1200px) {
    .modale-content[data-modale="signup"] {}
    .modale-content[data-modale="signin"] {}
}

@media (min-width: 1024px) {
    .modale-content[data-modale="signup"] {}
    .modale-content[data-modale="signin"] {}
}

@media (min-width: 768px) {
    .modale-content[data-modale="signup"] {}
    .modale-content[data-modale="signin"] {}
}

@media (min-width: 640px) {
    .modale-content[data-modale="signup"] {}
    .modale-content[data-modale="signin"] {}
}
