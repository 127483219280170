main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details h3 {
    font-size: 18px;
    font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #annonce-description {
    margin-top: 16px;
    background-color: var(--twc-gray-100);
    padding: 16px;
    white-space: pre-wrap;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #missings {
    margin-top: 32px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #missings .annonce-missing_data {

}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces {
    margin-top: 32px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces h3 {
    
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 16px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 200px;
    min-width: 264px;
    position: relative;
    overflow: hidden;
    box-shadow: var(--clear-thin-shadow-5);
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked h4 {
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-pic {
    padding: 10px;
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0s;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked:hover .annonce-linked-details-pic {
    opacity: 0.05;
    transition: opacity 0.2s ease-in-out 0.1s;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general {
    position: absolute;
    width: 100%;
    height: calc(100% - 40px);
    top: 100%;
    transition: top 0.1s ease-in-out 0s;
    padding: 8px;
    background-color: var(--twc-pink-700-f8);
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked:hover .annonce-linked-details-general {
    top: 40px;
    transition: top 0.2s ease-in-out 0.1s;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem {
    
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem:not(:first-child) {
    margin-top: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem img {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem span {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 28px);
}

@media (max-width: 1199px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
        width: 60%;
    }
}

@media (max-width: 767px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
        padding: 16px 5px;
        width: 100%;
    }
}

@media (max-width: 424px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
        display: block;
        width: 100%;
        padding: 16px 5px;
    }
}

@media (min-width: 768px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
        display: inline-block;
        vertical-align: top;
        padding: 24px 10px;
    }
}

@media (min-width: 1200px) {
    main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
        width: 80%;
        padding: 32px 15px;
    }
}