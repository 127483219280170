:root {
  --clear-shadow-5: #0000000d 2px 2px 6px 4px;
  --clear-thin-shadow-5: #0000000d 2px 2px 6px 2px;
  --clear-shadow-10: #0000001a 2px 2px 6px 4px;
  --clear-thin-shadow-10: #0000001a 2px 2px 6px 2px;
  --clear-shadow-15: #00000026 2px 2px 6px 4px;
  --clear-thin-shadow-15: #00000026 2px 2px 6px 2px;
  --dark-shadow-5: #0000004d 2px 2px 6px 4px;
  --dark-thin-shadow-5: #0000004d 2px 2px 6px 2px;
  --dark-shadow-10: #0006 2px 2px 6px 4px;
  --dark-thin-shadow-10: #0006 2px 2px 6px 2px;
  --dark-shadow-15: #00000080 2px 2px 6px 4px;
  --dark-thin-shadow-15: #00000080 2px 2px 6px 2px;
  --casting-block: #fbbf24;
  --essayage-block: #34d399;
  --tournage-block: #f87171;
  --repetition-block: #60a5fa;
  --photo-block: #8b5cf6;
  --unk-block: #d1d5db;
  --doublure-block: #f87171;
  --silhouette-block: #f87171;
  --casting-block-darken: #d29804;
  --essayage-block-darken: #24a374;
  --repetition-block-darken: #0d76f8;
  --tournage-block-darken: #eb0b0b;
  --photo-block-darken: #510df0;
  --unk-block-darken: #959eac;
  --twc-black-f8: #00000080;
  --twc-black: #000;
  --twc-white-f8: #ffffff80;
  --twc-white: #fff;
  --twc-gray-50: #f9fafb;
  --twc-gray-50-f8: #f9fafb80;
  --twc-gray-100: #f3f4f6;
  --twc-gray-100-f8: #f3f4f680;
  --twc-gray-200: #e5e7eb;
  --twc-gray-200-f8: #e5e7eb80;
  --twc-gray-300: #d1d5db;
  --twc-gray-300-f8: #d1d5db80;
  --twc-gray-400: #9ca3af;
  --twc-gray-400-f8: #9ca3af80;
  --twc-gray-500: #6b7280;
  --twc-gray-500-f8: #6b728080;
  --twc-gray-600: #4b5563;
  --twc-gray-600-f8: #4b556380;
  --twc-gray-700: #374151;
  --twc-gray-700-f8: #37415180;
  --twc-gray-800: #1f2937;
  --twc-gray-800-f8: #1f293780;
  --twc-gray-900: #111827;
  --twc-gray-900-f8: #11182780;
  --twc-red-50: #fef2f2;
  --twc-red-50-f8: #fef2f280;
  --twc-red-100: #fee2e2;
  --twc-red-100-f8: #fee2e280;
  --twc-red-200: #fecaca;
  --twc-red-200-f8: #fecaca80;
  --twc-red-300: #fca5a5;
  --twc-red-300-f8: #fca5a580;
  --twc-red-400: #f87171;
  --twc-red-400-f8: #f8717180;
  --twc-red-500: #ef4444;
  --twc-red-500-f8: #ef444480;
  --twc-red-600: #dc2626;
  --twc-red-600-f8: #dc262680;
  --twc-red-700: #b91c1c;
  --twc-red-700-f8: #b91c1c80;
  --twc-red-800: #991b1b;
  --twc-red-800-f8: #991b1b80;
  --twc-red-900: #7f1d1d;
  --twc-red-900-f8: #7f1d1d80;
  --twc-yellow-50: #fffbeb;
  --twc-yellow-50-f8: #fffbeb80;
  --twc-yellow-100: #fef3c7;
  --twc-yellow-100-f8: #fef3c780;
  --twc-yellow-200: #fde68a;
  --twc-yellow-200-f8: #fde68a80;
  --twc-yellow-300: #fcd34d;
  --twc-yellow-300-f8: #fcd34d80;
  --twc-yellow-400: #fbbf24;
  --twc-yellow-400-f8: #fbbf2480;
  --twc-yellow-500: #f59e0b;
  --twc-yellow-500-f8: #f59e0b80;
  --twc-yellow-600: #d97706;
  --twc-yellow-600-f8: #d9770680;
  --twc-yellow-700: #b45309;
  --twc-yellow-700-f8: #b4530980;
  --twc-yellow-800: #92400e;
  --twc-yellow-800-f8: #92400e80;
  --twc-yellow-900: #78350f;
  --twc-yellow-900-f8: #78350f80;
  --twc-green-50: #ecfdf5;
  --twc-green-50-f8: #ecfdf580;
  --twc-green-100: #d1fae5;
  --twc-green-100-f8: #d1fae580;
  --twc-green-200: #a7f3d0;
  --twc-green-200-f8: #a7f3d080;
  --twc-green-300: #6ee7b7;
  --twc-green-300-f8: #6ee7b780;
  --twc-green-400: #34d399;
  --twc-green-400-f8: #34d39980;
  --twc-green-500: #10b981;
  --twc-green-500-f8: #10b98180;
  --twc-green-600: #059669;
  --twc-green-600-f8: #05966980;
  --twc-green-700: #047857;
  --twc-green-700-f8: #04785780;
  --twc-green-800: #065f46;
  --twc-green-800-f8: #065f4680;
  --twc-green-900: #064e3b;
  --twc-green-900-f8: #064e3b80;
  --twc-blue-50: #eff6ff;
  --twc-blue-50-f8: #eff6ff80;
  --twc-blue-100: #dbeafe;
  --twc-blue-100-f8: #dbeafe80;
  --twc-blue-200: #bfdbfe;
  --twc-blue-200-f8: #bfdbfe80;
  --twc-blue-300: #93c5fd;
  --twc-blue-300-f8: #93c5fd80;
  --twc-blue-400: #60a5fa;
  --twc-blue-400-f8: #60a5fa80;
  --twc-blue-500: #3b82f6;
  --twc-blue-500-f8: #3b82f680;
  --twc-blue-600: #2563eb;
  --twc-blue-600-f8: #2563eb80;
  --twc-blue-700: #1d4ed8;
  --twc-blue-700-f8: #1d4ed880;
  --twc-blue-800: #1e40af;
  --twc-blue-800-f8: #1e40af80;
  --twc-blue-900: #1e3a8a;
  --twc-blue-900-f8: #1e3a8a80;
  --twc-indigo-50: #eef2ff;
  --twc-indigo-50-f8: #eef2ff80;
  --twc-indigo-100: #e0e7ff;
  --twc-indigo-100-f8: #e0e7ff80;
  --twc-indigo-200: #c7d2fe;
  --twc-indigo-200-f8: #c7d2fe80;
  --twc-indigo-300: #a5b4fc;
  --twc-indigo-300-f8: #a5b4fc80;
  --twc-indigo-400: #818cf8;
  --twc-indigo-400-f8: #818cf880;
  --twc-indigo-500: #6366f1;
  --twc-indigo-500-f8: #6366f180;
  --twc-indigo-600: #4f46e5;
  --twc-indigo-600-f8: #4f46e580;
  --twc-indigo-700: #4338ca;
  --twc-indigo-700-f8: #4338ca80;
  --twc-indigo-800: #3730a3;
  --twc-indigo-800-f8: #3730a380;
  --twc-indigo-900: #312e81;
  --twc-indigo-900-f8: #312e8180;
  --twc-purple-50: #f5f3ff;
  --twc-purple-50-f8: #f5f3ff80;
  --twc-purple-100: #ede9fe;
  --twc-purple-100-f8: #ede9fe80;
  --twc-purple-200: #ddd6fe;
  --twc-purple-200-f8: #ddd6fe80;
  --twc-purple-300: #c4b5fd;
  --twc-purple-300-f8: #c4b5fd80;
  --twc-purple-400: #a78bfa;
  --twc-purple-400-f8: #a78bfa80;
  --twc-purple-500: #8b5cf6;
  --twc-purple-500-f8: #8b5cf680;
  --twc-purple-600: #7c3aed;
  --twc-purple-600-f8: #7c3aed80;
  --twc-purple-700: #6d28d9;
  --twc-purple-700-f8: #6d28d980;
  --twc-purple-800: #5b21b6;
  --twc-purple-800-f8: #5b21b680;
  --twc-purple-900: #4c1d95;
  --twc-purple-900-f8: #4c1d9580;
  --twc-pink-50: #fdf2f8;
  --twc-pink-50-f8: #fdf2f880;
  --twc-pink-100: #fce7f3;
  --twc-pink-100-f8: #fce7f380;
  --twc-pink-200: #fbcfe8;
  --twc-pink-200-f8: #fbcfe880;
  --twc-pink-300: #f9a8d4;
  --twc-pink-300-f8: #f9a8d480;
  --twc-pink-400: #f472b6;
  --twc-pink-400-f8: #f472b680;
  --twc-pink-500: #ec4899;
  --twc-pink-500-f8: #ec489980;
  --twc-pink-600: #db2777;
  --twc-pink-600-f8: #db277780;
  --twc-pink-700: #be185d;
  --twc-pink-700-f8: #be185d80;
  --twc-pink-800: #9d174d;
  --twc-pink-800-f8: #9d174d80;
  --twc-pink-900: #831843;
  --twc-pink-900-f8: #83184380;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="range"] {
  accent-color: var(--twc-pink-700);
}

input[type="range"] + datalist {
  writing-mode: vertical-lr;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

input[type="range"] + datalist option {
  text-align: center;
  color: var(--twc-black);
  writing-mode: horizontal-tb;
  transform-origin: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0 0 0 5px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  transition: all .2s ease-in-out;
  transform: rotate(0);
}

input[type="range"] + datalist option:hover {
  color: var(--twc-pink-700);
}

input[type="range"]:hover {
  cursor: pointer;
  accent-color: var(--twc-pink-800);
}

input[type="range"]::-webkit-slider-runnable-track, input[type="range"]::-moz-range-track, input[type="range"]::-ms-track {
  accent-color: var(--twc-pink-500);
}

input[type="range"]::-webkit-slider-thumb, input[type="range"]::-moz-range-thumb, input[type="range"]::-ms-thumb {
  accent-color: var(--twc-pink-700);
}

#modale {
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: #0008;
  position: fixed;
  top: 0;
  left: 0;
}

#modale.opened {
  z-index: 9999;
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

#modale #modale-container {
  width: 60%;
  height: 80%;
  background-color: #333;
  border-radius: 7px;
  margin: 0 auto;
  position: relative;
  top: 10%;
  box-shadow: 0 0 5px #1118;
}

#modale #modale-container #modale-content {
  height: 100%;
}

#modale #modale-container #modale-content #modale-close {
  color: #fff;
  cursor: pointer;
  width: 30px;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  top: 15px;
  right: 15px;
}

:root {
  --modale-lang-item-height: 30px;
}

ul#modale-set-lng {
  width: 8rem;
  height: var(--modale-lang-item-height);
  z-index: 1;
  border-left: 2px solid var(--twc-pink-900);
  border-bottom: 2px solid var(--twc-pink-900);
  border-top-right-radius: .5rem;
  transition: height .3s ease-in-out;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

ul#modale-set-lng:hover {
  height: calc(var(--modale-lang-item-height) * attr(data-nb_langs));
}

ul#modale-set-lng .dropdown-menu, ul#modale-set-lng .dropdown-item {
  height: var(--modale-lang-item-height);
}

ul#modale-set-lng .dropdown-item {
  font-size: 10px;
}

ul#modale-set-lng .current-lng {
  color: var(--twc-white);
  background-color: var(--twc-pink-900);
  font-weight: bold;
}

@media only screen and (width <= 1199px) {
  ul#modale-set-lng {
    height: var(--mini-header-height);
    top: 0;
  }

  ul#modale-set-lng .dropdown-menu, ul#modale-set-lng .dropdown-item {
    height: var(--mini-header-height);
    line-height: calc(var(--mini-header-height)  - 1rem);
  }
}

:root {
  --header-height: 5rem;
}

input:checked ~ .dot {
  background-color: #831843;
  transform: translateX(100%);
}

thead {
  width: 100%;
}

thead, tbody tr {
  width: 100%;
  table-layout: fixed;
  display: table;
}

tbody {
  display: block;
  overflow-y: auto;
}

tr {
  white-space: nowrap;
}

th, td {
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

th {
  white-space: nowrap;
}

td {
  position: relative;
}

tr:nth-child(2n), tr:nth-child(2n) td {
  background: #fff;
}

tr:nth-child(odd), tr:nth-child(odd) td {
  background: #f1f5fb;
}

.table th {
  position: relative;
}

.resizer {
  width: 5px;
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
}

th:hover .resizer, .resizer:hover, .resizing {
  background-color: #00f;
}

th.jaj-calendar .resizer {
  left: 0;
}

.custom-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #be185d #f3f4f6;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #be185d #f3f4f6;
}

.no-scrollbar {
  scrollbar-width: none;
  scrollbar-color: #be185d #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar, .custom-scrollbar::-webkit-scrollbar-button, .custom-scrollbar::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
}

.thin-scrollbar::-webkit-scrollbar, .thin-scrollbar::-webkit-scrollbar-button, .thin-scrollbar::-webkit-scrollbar-thumb {
  width: 3px;
  height: 3px;
}

.no-scrollbar::-webkit-scrollbar, .no-scrollbar::-webkit-scrollbar-button, .no-scrollbar::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
}

.custom-scrollbar, .thin-scrollbar, .no-scrollbar {
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar, .thin-scrollbar::-webkit-scrollbar, .no-scrollbar::-webkit-scrollbar {
  background-color: #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar-button, .thin-scrollbar::-webkit-scrollbar-button, .no-scrollbar::-webkit-scrollbar-button {
  background-color: #831843;
}

.custom-scrollbar::-webkit-scrollbar-thumb, .thin-scrollbar::-webkit-scrollbar-thumb, .no-scrollbar::-webkit-scrollbar-thumb {
  background-color: #be185d;
}

.custom-scrollbar.black-scrollbar::-webkit-scrollbar, .thin-scrollbar.black-scrollbar::-webkit-scrollbar, .no-scrollbar.black-scrollbar::-webkit-scrollbar {
  background-color: #f3f4f6;
}

.custom-scrollbar.black-scrollbar::-webkit-scrollbar-button, .thin-scrollbar.black-scrollbar::-webkit-scrollbar-button, .no-scrollbar.black-scrollbar::-webkit-scrollbar-button {
  background-color: #000;
}

.custom-scrollbar.black-scrollbar::-webkit-scrollbar-thumb, .thin-scrollbar.black-scrollbar::-webkit-scrollbar-thumb, .no-scrollba.black-scrollbarr::-webkit-scrollbar-thumb {
  background-color: #111;
}

a.absolute-link {
  width: 100%;
  height: 100%;
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#wrapper {
  width: calc(100% - 3.5rem);
}

#wrapper.unlog, #wrapper[data-size="full"] {
  width: 100%;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.border-modere {
  border-right: 5px solid orange;
}

.border-urgent {
  border-right: 5px solid red;
}

.border-tranquille {
  border-right: 5px solid green;
}

.hover-text-bold:hover {
  font-weight: 600;
}

.header-project-create:hover {
  transition: background .2s ease-in-out;
}

.header-project-link {
  border-radius: .25rem;
  padding: 4px 12px;
  transition: background .2s ease-in-out, color .2s ease-in-out, font-weight .2s ease-in-out;
}

.header-project-link:hover {
  background-color: var(--twc-black);
  color: var(--twc-white);
}

form label.required:not(.hide-star):after {
  content: "*";
  color: #831843;
  margin-left: 2px;
  font-size: .875rem;
  line-height: 1.25rem;
}

form .hide-star label.required:after, form label.required.hide-star:after {
  content: "";
}

.show-mode div.inactive, .show-mode input.inactive, .show-mode textarea.inactive, .edit-mode div.inactive, .edit-mode input.inactive, .edit-mode textarea.inactive {
  display: none;
}

.show-mode input, .show-mode textarea, .edit-mode input, .edit-mode textarea {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  line-height: 1;
}

.edit-mode textarea {
  resize: none;
}

.show-mode div.active, .show-mode input.active, .show-mode textarea.active, .edit-mode div.active, .edit-mode input.active, .edit-mode textarea.active {
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edit-mode input.active {
  color: #000;
  z-index: 1;
  background: #fff;
  padding: 0 .125rem;
  font-weight: 600;
  position: relative;
  box-shadow: 1px 1px 4px 2px #1118;
}

.edit-mode textarea.active {
  color: #000;
  padding: 0 .125rem;
  font-weight: 600;
}

#page-loader {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1f1f1fe6;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 45px;
  left: 0;
}

#page-loader img {
  width: 20rem;
}

.loading-gif {
  margin: 0 auto;
  position: relative;
}

.loading-gif .loader-circle, .loading-gif .loader-bar {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-gif.extra-small, .loading-gif.extra-small .loader-circle, .loading-gif.extra-small .loader-bar {
  width: 10px;
  height: 10px;
}

.loading-gif.mini, .loading-gif.mini .loader-circle, .loading-gif.mini .loader-bar {
  width: 20px;
  height: 20px;
}

.loading-gif, .loading-gif .loader-circle, .loading-gif .loader-bar {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.loading-gif.large, .loading-gif.large .loader-circle, .loading-gif.large .loader-bar {
  width: 40px;
  height: 40px;
}

.loading-gif.extra-large, .loading-gif.extra-large .loader-circle, .loading-gif.extra-large .loader-bar {
  width: 60px;
  height: 60px;
}

.loading-gif .loader-circle {
  border: 5px solid #f3f3f3;
}

.loading-gif .loader-bar {
  border: 5px solid #0000;
  border-top-color: #831843;
  animation: 1.5s cubic-bezier(0, .07, .8, .88) infinite spin;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-mask {
  width: 100%;
  height: 100%;
  background-color: var(--twc-gray-300-f8);
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-mask .loading-gif {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mask {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.mask-error {
  background-image: linear-gradient(45deg, #8004, #8002, #8001);
  border-color: #f00a;
  border-top-style: solid;
  border-top-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.mask-success {
  background-image: linear-gradient(45deg, #0803, #0802, #0801);
  border-color: #0f0a;
  border-top-style: solid;
  border-top-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.expand-view, .retract-view {
  cursor: pointer;
}

.retract-view {
  transform: scaleY(-1);
}

.cheeks-lock {
  width: 50px;
  height: 60px;
  position: relative;
}

.cheeks-lock:not(.opened):before, .cheeks-lock:not(.opened):after {
  border-color: red;
}

.cheeks-lock.opened:before, .cheeks-lock.opened:after {
  border-color: green;
}

.cheeks-lock:before {
  content: "";
  width: 60%;
  height: 50%;
  box-sizing: border-box;
  border: 2px solid;
  border-bottom: none;
  border-radius: 30% 30% 0 0;
  transition: transform .3s cubic-bezier(.33, -.59, .27, 1.15), height .3s ease-in-out, border .3s ease-in-out;
  position: absolute;
  top: 3.5%;
  left: 20%;
  transform: translate3d(0, 0, 0)rotateZ(0);
}

.cheeks-lock.opened:before {
  height: 40%;
  transition: transform .3s ease-in-out, height .3s ease-in-out, border .3s ease-in-out;
  transform: translate3d(-10%, -8%, 0)rotateZ(-20deg);
}

.cheeks-lock:after {
  content: "";
  width: 100%;
  height: 60%;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid;
  transition: border .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}





:root {
  --lang-item-height: 40px;
}

header {
  height: var(--header-height);
  z-index: 1;
  box-shadow: var(--clear-thin-shadow-5);
  position: relative;
}

@media only screen and (width <= 1199px) {
  header {
    height: var(--mini-header-height);
  }
}

header nav#header-nav a {
  height: 1.5rem;
  display: inline-block;
}

header nav#header-nav a#header-logo img {
  height: 100%;
}

header nav#header-nav a:not(#header-logo) {
  padding: 0 15px;
}

header ul#header-set-lng {
  width: 10rem;
  height: var(--lang-item-height);
  transition: height .3s ease-in-out;
  position: fixed;
  top: 1rem;
  right: 0;
}

header ul#header-set-lng:hover {
  height: calc(var(--lang-item-height) * attr(data-nb_langs));
}

header ul#header-set-lng .dropdown-menu, header ul#header-set-lng .dropdown-item {
  height: var(--lang-item-height);
}

header ul#header-set-lng .current-lng {
  color: var(--twc-white);
  background-color: var(--twc-pink-900);
  font-weight: bold;
}

@media only screen and (width <= 1199px) {
  header ul#header-set-lng {
    height: var(--mini-header-height);
    top: 0;
  }

  header ul#header-set-lng .dropdown-menu, header ul#header-set-lng .dropdown-item {
    height: var(--mini-header-height);
    line-height: calc(var(--mini-header-height)  - 1rem);
  }
}

#hero-header {
  text-align: right;
  z-index: 0;
  position: relative;
}

#hero-header #leading-content {
  width: 690px;
  text-align: left;
  z-index: 1;
  position: absolute;
  top: 15vh;
  left: 4rem;
}

@media only screen and (width <= 1199px) {
  #hero-header #leading-content {
    width: 50%;
  }
}

#hero-header #leading-content #hero-leading-cta-main1, #hero-header #leading-content #hero-leading-cta-main2 {
  letter-spacing: -.025rem;
  width: 100%;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: var(--extrabold);
  display: block;
}

#hero-header #leading-content #hero-leading-cta-main1 {
  color: var(--twc-gray-900);
}

#hero-header #leading-content #hero-leading-cta-main2 {
  color: var(--twc-pink-900);
}

#hero-header #leading-content #hero-leading-cta-text {
  color: var(--twc-gray-600);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#hero-header #leading-content a#hero-leading-cta-signup-lnk {
  font-size: 1.25rem;
  display: block;
}

#hero-header #leading-content a#hero-leading-cta-signup-btn {
  vertical-align: top;
  margin-top: 2rem;
  margin-right: .75rem;
  display: inline-block;
  position: relative;
}

#hero-header #leading-content button#hero-leading-cta-movies {
  vertical-align: top;
  margin-top: 2rem;
  display: inline-block;
}

#hero-header #banner-image {
  width: 50%;
  vertical-align: middle;
  z-index: 0;
  display: inline-block;
  position: relative;
}

#hero-header #banner-image img#banner-image-path {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
}

#hero-header #banner-image img#banner-image-picture {
  width: 100%;
  height: calc(100vh - var(--header-height));
}

main[data-page="annoncement"] #annonces-filter {
  box-shadow: var(--clear-thin-shadow-5);
  position: relative;
}

main[data-page="annoncement"] #annonces-filter .loading-gif {
  width: 30px;
  height: 30px;
}

@media (width >= 0) {
  main[data-page="annoncement"] #annonces-filter {
    width: 100%;
    padding: 10px 4px;
    display: block;
  }
}

@media (width >= 768px) {
  main[data-page="annoncement"] #annonces-filter {
    flex: 3;
    padding: 10px 4px;
    display: block;
  }
}

@media (width >= 1199px) {
  main[data-page="annoncement"] #annonces-filter {
    flex: 3;
    padding: 15px 8px;
    display: block;
  }
}

@media (width >= 1200px) {
  main[data-page="annoncement"] #annonces-filter {
    flex: 2;
    padding: 25px 12px;
    display: block;
  }
}

main[data-page="annoncement"] #annonces-filter h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 600;
}

main[data-page="annoncement"] #annonces-filter .filter-zone > [id^="filter-"]:not(:empty) {
  border-bottom: 1px solid var(--twc-gray-300);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

main[data-page="annoncement"] #annonces-filter .filter-zone > [id^="filter-"]:last-child {
  border-bottom: none;
}

main[data-page="annoncement"] #annonces-filter .filter-zone #filter-sexe-male, main[data-page="annoncement"] #annonces-filter .filter-zone #filter-sexe-female, main[data-page="annoncement"] #annonces-filter .filter-zone #filter-animals, main[data-page="annoncement"] #annonces-filter .filter-zone #filter-vehicles {
  margin-top: 10px;
}



main[data-page="annoncement"] #annonces-list .loading-gif {
  width: 30px;
  height: 30px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
  box-shadow: var(--clear-thin-shadow-5);
  width: 100%;
  display: block;
  position: relative;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:hover {
  background-color: var(--twc-gray-400-f8);
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
  margin-top: 8px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 4px 8px 12px;
  font-size: 18px;
  font-weight: 500;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
  margin-top: 8px;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  display: block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
  display: none;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic img {
  width: 100%;
  box-sizing: border-box;
  max-height: 160px;
  display: block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  display: block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 4px;
  display: block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
  width: 50%;
  vertical-align: top;
  text-align: center;
  margin-bottom: 0;
  padding: 4px;
  display: inline-block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem img {
  width: 24px;
  vertical-align: middle;
  margin: 0 auto;
  font-size: 0;
  display: inline-block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem span {
  text-align: center;
  width: 100%;
  margin-top: 4px;
  display: block;
}

main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid var(--twc-gray-800);
  display: block;
}

@media (width >= 768px) {
  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
    width: 100%;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
    margin-top: 8px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
    font-size: 20px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
    margin-top: 10px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
    display: none;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 6px;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
    width: 50%;
    vertical-align: top;
    margin-bottom: 0;
    padding: 4px 8px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
}

@media (width >= 864px) {
  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
    width: 160px;
    vertical-align: top;
    box-sizing: border-box;
    padding: 8px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
    width: calc(100% - 160px);
    vertical-align: top;
    box-sizing: border-box;
    padding: 12px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
    width: 25%;
    vertical-align: top;
    margin-bottom: 0;
    padding: 8px;
    display: inline-block;
  }
}

@media (width >= 1200px) {
  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] {
    width: 100%;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"]:not(:first-child) {
    margin-top: 12px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-title {
    font-size: 22px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details {
    margin-top: 16px;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic {
    width: 160px;
    vertical-align: top;
    box-sizing: border-box;
    padding: 8px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty {
    display: none;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr {
    width: calc(100% - 160px);
    vertical-align: top;
    box-sizing: border-box;
    padding: 12px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-pic:empty + .annonce-elem-details-descr {
    width: 100%;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 8px;
    display: block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-short .annonce-elem-details-descr-short-elem {
    width: 25%;
    vertical-align: top;
    margin-bottom: 0;
    padding: 8px;
    display: inline-block;
  }

  main[data-page="annoncement"] #annonces-list [id^="annonce-elem-"] .annonce-elem-details .annonce-elem-details-descr .annonce-elem-details-descr-abstract {
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
}

main[data-page="annoncement"] #annonces-list {
  padding: 10px 4px;
  position: relative;
}

@media (width >= 0) {
  main[data-page="annoncement"] #annonces-list {
    width: 100%;
    padding: 10px 4px;
    display: block;
  }
}

@media (width >= 768px) {
  main[data-page="annoncement"] #annonces-list {
    flex: 6;
    padding: 10px 4px;
  }
}

@media (width >= 1199px) {
  main[data-page="annoncement"] #annonces-list {
    flex: 6;
    padding: 15px 8px;
  }
}

@media (width >= 1200px) {
  main[data-page="annoncement"] #annonces-list {
    flex: 6;
    padding: 25px 12px;
  }
}

main[data-page="annoncement"] #annonces-projects {
  box-shadow: var(--clear-thin-shadow-5);
  padding: 10px 4px;
  position: relative;
}

main[data-page="annoncement"] #annonces-projects .loading-gif {
  width: 30px;
  height: 30px;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem {
  position: relative;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem:not(:first-child) {
  margin-top: 8px;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem h3 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 8px;
  font-size: 18px;
  font-weight: 500;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem .annonces-projects-elem-poster {
  margin-top: 4px;
  padding: 6px;
}

@media (width >= 0) {
  main[data-page="annoncement"] #annonces-projects {
    padding: 10px 4px;
    display: none;
  }
}

@media (width >= 768px) {
  main[data-page="annoncement"] #annonces-projects {
    padding: 10px 4px;
    display: none;
  }
}

@media (width >= 1199px) {
  main[data-page="annoncement"] #annonces-projects {
    flex: 2;
    padding: 15px 8px;
    display: block;
  }
}

@media (width >= 1200px) {
  main[data-page="annoncement"] #annonces-projects {
    min-width: 200px;
    flex: 1;
    padding: 25px 12px;
    display: block;
  }
}

main[data-page="annonce"] #annonce-bandeau {
  width: 100%;
  background-color: var(--twc-white);
  box-shadow: var(--clear-thin-shadow-5);
  top: var(--header-height);
  z-index: 1;
  padding: 8px;
  position: fixed;
  left: 0;
}

main[data-page="annonce"] #annonce-bandeau .annonce-title {
  letter-spacing: .2px;
  color: var(--twc-black);
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat, main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
  width: 49%;
  height: 32px;
  text-align: center;
  display: inline-block;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
  margin-right: 2%;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat.saving span {
  display: none;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat #annonce-bandeau-candidat-waiter {
  width: 16px;
  height: 16px;
  display: none;
}

main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat.saving #annonce-bandeau-candidat-waiter {
  display: block;
}

@media only screen and (width <= 1199px) {
  main[data-page="annonce"] #annonce-bandeau {
    top: var(--mini-header-height);
  }
}

@media (width <= 767px) {
  main[data-page="annonce"] #annonce-bandeau {
    box-shadow: var(--clear-thin-shadow-10);
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-title {
    margin: 12px auto 14px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat, main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
    width: calc(50% - 16px);
    vertical-align: middle;
    display: inline-block;
    position: relative;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
    margin-right: 16px;
  }
}

@media (width <= 424px) {
  main[data-page="annonce"] #annonce-bandeau .annonce-title {
    margin: 8px auto;
  }
}

@media (width >= 425px) {
  main[data-page="annonce"] #annonce-bandeau {
    padding: 12px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-title {
    width: 100%;
    display: block;
    position: relative;
  }
}

@media (width >= 768px) {
  main[data-page="annonce"] #annonce-bandeau {
    height: 64px;
    padding: 12px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-title {
    max-width: calc(100% - 448px);
    position: absolute;
    top: 22px;
    left: 10px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat, main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
    width: 200px;
    height: 32px;
    position: absolute;
    top: 16px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat {
    right: 212px;
  }

  main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-complete {
    right: 16px;
  }
}

@media (width >= 1200px) {
  main[data-page="annonce"] #annonce-bandeau {
    height: 64px;
    padding: 16px;
  }
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-poster {
  display: none;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos, main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals, main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-project_poster, main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project {
  box-shadow: var(--clear-thin-shadow-5);
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos:not(:first-child), main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals:not(:first-child), main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-project_poster:not(:first-child), main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project:not(:first-child) {
  margin-top: 16px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos h3 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 8px;
  font-size: 18px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
  width: 100%;
  margin-bottom: 4px;
  padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem img {
  width: 24px;
  vertical-align: top;
  font-size: 0;
  display: inline-block;
  position: relative;
  top: 2px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem span {
  width: calc(100% - 32px);
  vertical-align: top;
  white-space: pre-wrap;
  margin-left: 8px;
  display: inline-block;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals h3 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 8px;
  font-size: 18px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details {
  padding: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details h3 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 6px;
  font-size: 16px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details h3:not(:first-child) {
  margin-top: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-practicals #annonce-infos-practicals-details div {
  margin-top: 4px;
  margin-left: 4px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project h3 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 6px;
  font-size: 16px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project #annonce-infos-project_poster {
  display: none;
}

@media (width <= 1199px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
    width: 40%;
    padding: 10px;
  }
}

@media (width <= 767px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
    width: 100%;
    padding: 5px;
    display: block;
  }

  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
    width: 50%;
    vertical-align: top;
    display: inline-block;
  }
}

@media (width <= 424px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-infos #annonce-infos-infos-details .annonce-elem-details-descr-short-elem {
    width: 100%;
    display: block;
  }
}

@media (width >= 768px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
    vertical-align: top;
    display: inline-block;
  }

  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-poster, main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general #annonce-infos-project #annonce-infos-project_poster {
    width: 100%;
    display: block;
  }
}

@media (width >= 1200px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-general {
    width: 20%;
    padding: 10px;
  }
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details h3 {
  font-size: 18px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #annonce-description {
  background-color: var(--twc-gray-100);
  white-space: pre-wrap;
  margin-top: 16px;
  padding: 16px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #missings, main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces {
  margin-top: 32px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list {
  width: 100%;
  flex-flow: row;
  column-gap: 16px;
  padding: 8px;
  display: flex;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked {
  vertical-align: top;
  width: 100%;
  max-width: 200px;
  min-width: 264px;
  box-shadow: var(--clear-thin-shadow-5);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked h4 {
  width: 100%;
  text-transform: uppercase;
  color: var(--twc-white);
  background-color: var(--twc-gray-800);
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-pic {
  opacity: 1;
  padding: 10px;
  transition: opacity .1s ease-in-out;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked:hover .annonce-linked-details-pic {
  opacity: .05;
  transition: opacity .2s ease-in-out .1s;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general {
  width: 100%;
  height: calc(100% - 40px);
  background-color: var(--twc-pink-700-f8);
  padding: 8px;
  transition: top .1s ease-in-out;
  position: absolute;
  top: 100%;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked:hover .annonce-linked-details-general {
  transition: top .2s ease-in-out .1s;
  top: 40px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem:not(:first-child) {
  margin-top: 8px;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem img {
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin-right: 4px;
  display: inline-block;
}

main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details #linked-annonces #linked-annonces-list .annonce-infos-details-linked .annonce-linked-details-general-elem span {
  vertical-align: top;
  width: calc(100% - 28px);
  display: inline-block;
}

@media (width <= 1199px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    width: 60%;
  }
}

@media (width <= 767px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    width: 100%;
    padding: 16px 5px;
  }
}

@media (width <= 424px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    width: 100%;
    padding: 16px 5px;
    display: block;
  }
}

@media (width >= 768px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    vertical-align: top;
    padding: 24px 10px;
    display: inline-block;
  }
}

@media (width >= 1200px) {
  main[data-page="annonce"] #annonce-details #annonce-infos #annonce-infos-details {
    width: 80%;
    padding: 32px 15px;
  }
}



@media (width <= 1199px) {
  #annonce-details #annonce-faq {
    width: 60%;
    padding: 20px;
  }
}

@media (width <= 767px) {
  #annonce-details #annonce-faq {
    padding: 15px;
  }
}

@media (width <= 424px) {
  #annonce-details #annonce-faq {
    width: 100%;
    padding: 10px;
    display: block;
  }
}

@media (width >= 768px) {
  #annonce-details #annonce-faq {
    vertical-align: top;
    display: inline-block;
  }
}

@media (width >= 1200px) {
  #annonce-details #annonce-faq {
    width: 80%;
    padding: 25px;
  }
}

:root {
  --myspace-bg: #342b2d;
  --myspace-general-bg: #d9d9d9;
  --myspace-general-txt: #000;
  --myspace-general-txt-other: #868686;
  --myspace-general-txt-other-bg: #868686;
  --myspace-tabs-bg: #989898;
  --myspace-current-tab-bg: #d9d9d9;
  --myspace-tab-hover: #707070;
  --myspace-current-tab-hover: #acacac;
  --myspace-display-box-bg: #25191c;
  --myspace-display-box-title: #ff7597;
  --myspace-display-box-text: #fff;
  --general-height: 3rem;
  --photo-height: 9rem;
  --photo-v-padding: 1.5rem;
  --tabs-height: 3.5rem;
}

#myspace {
  width: 100%;
  height: 100%;
  padding: 0;
}

#myspace #myspace-photos #myspace-photos-carousel .photo img {
  height: 100%;
}

#myspace #tabs {
  height: var(--tabs-height);
}

#myspace #display {
  max-height: calc(100% - (var(--general-height)  + var(--photo-height)  + (2 * var(--photo-v-padding))  + var(--tabs-height)));
}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part:empty {
  border: none;
}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part div {
  display: block;
}



:root {
  --clear-shadow-5: #0000000d 2px 2px 6px 4px;
  --clear-thin-shadow-5: #0000000d 2px 2px 6px 2px;
  --clear-shadow-10: #0000001a 2px 2px 6px 4px;
  --clear-thin-shadow-10: #0000001a 2px 2px 6px 2px;
  --clear-shadow-15: #00000026 2px 2px 6px 4px;
  --clear-thin-shadow-15: #00000026 2px 2px 6px 2px;
  --dark-shadow-5: #0000004d 2px 2px 6px 4px;
  --dark-thin-shadow-5: #0000004d 2px 2px 6px 2px;
  --dark-shadow-10: #0006 2px 2px 6px 4px;
  --dark-thin-shadow-10: #0006 2px 2px 6px 2px;
  --dark-shadow-15: #00000080 2px 2px 6px 4px;
  --dark-thin-shadow-15: #00000080 2px 2px 6px 2px;
  --header-height: 80px;
  --mini-header-height: 50px;
  --fixed-header-height: 40px;
  --thin: 100;
  --extralight: 200;
  --light: 300;
  --normal: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
  --black-f8: #00000080;
  --black: #000;
  --white-f8: #ffffff80;
  --white: #fff;
  --gray-50: #f9fafb;
  --gray-50-f8: #f9fafb80;
  --gray-100: #f3f4f6;
  --gray-100-f8: #f3f4f680;
  --gray-200: #e5e7eb;
  --gray-200-f8: #e5e7eb80;
  --gray-300: #d1d5db;
  --gray-300-f8: #d1d5db80;
  --gray-400: #9ca3af;
  --gray-400-f8: #9ca3af80;
  --gray-500: #6b7280;
  --gray-500-f8: #6b728080;
  --gray-600: #4b5563;
  --gray-600-f8: #4b556380;
  --gray-700: #374151;
  --gray-700-f8: #37415180;
  --gray-800: #1f2937;
  --gray-800-f8: #1f293780;
  --gray-900: #111827;
  --gray-900-f8: #11182780;
  --red-50: #fef2f2;
  --red-50-f8: #fef2f280;
  --red-100: #fee2e2;
  --red-100-f8: #fee2e280;
  --red-200: #fecaca;
  --red-200-f8: #fecaca80;
  --red-300: #fca5a5;
  --red-300-f8: #fca5a580;
  --red-400: #f87171;
  --red-400-f8: #f8717180;
  --red-500: #ef4444;
  --red-500-f8: #ef444480;
  --red-600: #dc2626;
  --red-600-f8: #dc262680;
  --red-700: #b91c1c;
  --red-700-f8: #b91c1c80;
  --red-800: #991b1b;
  --red-800-f8: #991b1b80;
  --red-900: #7f1d1d;
  --red-900-f8: #7f1d1d80;
  --yellow-50: #fffbeb;
  --yellow-50-f8: #fffbeb80;
  --yellow-100: #fef3c7;
  --yellow-100-f8: #fef3c780;
  --yellow-200: #fde68a;
  --yellow-200-f8: #fde68a80;
  --yellow-300: #fcd34d;
  --yellow-300-f8: #fcd34d80;
  --yellow-400: #fbbf24;
  --yellow-400-f8: #fbbf2480;
  --yellow-500: #f59e0b;
  --yellow-500-f8: #f59e0b80;
  --yellow-600: #d97706;
  --yellow-600-f8: #d9770680;
  --yellow-700: #b45309;
  --yellow-700-f8: #b4530980;
  --yellow-800: #92400e;
  --yellow-800-f8: #92400e80;
  --yellow-900: #78350f;
  --yellow-900-f8: #78350f80;
  --green-50: #ecfdf5;
  --green-50-f8: #ecfdf580;
  --green-100: #d1fae5;
  --green-100-f8: #d1fae580;
  --green-200: #a7f3d0;
  --green-200-f8: #a7f3d080;
  --green-300: #6ee7b7;
  --green-300-f8: #6ee7b780;
  --green-400: #34d399;
  --green-400-f8: #34d39980;
  --green-500: #10b981;
  --green-500-f8: #10b98180;
  --green-600: #059669;
  --green-600-f8: #05966980;
  --green-700: #047857;
  --green-700-f8: #04785780;
  --green-800: #065f46;
  --green-800-f8: #065f4680;
  --green-900: #064e3b;
  --green-900-f8: #064e3b80;
  --blue-50: #eff6ff;
  --blue-50-f8: #eff6ff80;
  --blue-100: #dbeafe;
  --blue-100-f8: #dbeafe80;
  --blue-200: #bfdbfe;
  --blue-200-f8: #bfdbfe80;
  --blue-300: #93c5fd;
  --blue-300-f8: #93c5fd80;
  --blue-400: #60a5fa;
  --blue-400-f8: #60a5fa80;
  --blue-500: #3b82f6;
  --blue-500-f8: #3b82f680;
  --blue-600: #2563eb;
  --blue-600-f8: #2563eb80;
  --blue-700: #1d4ed8;
  --blue-700-f8: #1d4ed880;
  --blue-800: #1e40af;
  --blue-800-f8: #1e40af80;
  --blue-900: #1e3a8a;
  --blue-900-f8: #1e3a8a80;
  --indigo-50: #eef2ff;
  --indigo-50-f8: #eef2ff80;
  --indigo-100: #e0e7ff;
  --indigo-100-f8: #e0e7ff80;
  --indigo-200: #c7d2fe;
  --indigo-200-f8: #c7d2fe80;
  --indigo-300: #a5b4fc;
  --indigo-300-f8: #a5b4fc80;
  --indigo-400: #818cf8;
  --indigo-400-f8: #818cf880;
  --indigo-500: #6366f1;
  --indigo-500-f8: #6366f180;
  --indigo-600: #4f46e5;
  --indigo-600-f8: #4f46e580;
  --indigo-700: #4338ca;
  --indigo-700-f8: #4338ca80;
  --indigo-800: #3730a3;
  --indigo-800-f8: #3730a380;
  --indigo-900: #312e81;
  --indigo-900-f8: #312e8180;
  --purple-50: #f5f3ff;
  --purple-50-f8: #f5f3ff80;
  --purple-100: #ede9fe;
  --purple-100-f8: #ede9fe80;
  --purple-200: #ddd6fe;
  --purple-200-f8: #ddd6fe80;
  --purple-300: #c4b5fd;
  --purple-300-f8: #c4b5fd80;
  --purple-400: #a78bfa;
  --purple-400-f8: #a78bfa80;
  --purple-500: #8b5cf6;
  --purple-500-f8: #8b5cf680;
  --purple-600: #7c3aed;
  --purple-600-f8: #7c3aed80;
  --purple-700: #6d28d9;
  --purple-700-f8: #6d28d980;
  --purple-800: #5b21b6;
  --purple-800-f8: #5b21b680;
  --purple-900: #4c1d95;
  --purple-900-f8: #4c1d9580;
  --pink-50: #fdf2f8;
  --pink-50-f8: #fdf2f880;
  --pink-100: #fce7f3;
  --pink-100-f8: #fce7f380;
  --pink-200: #fbcfe8;
  --pink-200-f8: #fbcfe880;
  --pink-300: #f9a8d4;
  --pink-300-f8: #f9a8d480;
  --pink-400: #f472b6;
  --pink-400-f8: #f472b680;
  --pink-500: #ec4899;
  --pink-500-f8: #ec489980;
  --pink-600: #db2777;
  --pink-600-f8: #db277780;
  --pink-700: #be185d;
  --pink-700-f8: #be185d80;
  --pink-800: #9d174d;
  --pink-800-f8: #9d174d80;
  --pink-900: #831843;
  --pink-900-f8: #83184380;
}

@media (width >= 0) {
  main {
    padding: 5px 5px 10px;
  }
}

@media (width >= 768px) {
  main {
    padding: 10px 10px 15px;
  }

  main[data-page="annoncement"] {
    column-gap: 2%;
    display: flex;
  }
}

@media (width >= 1199px) {
  main {
    padding: 15px 15px 25px;
  }
}

@media (width >= 1200px) {
  main {
    padding: 25px 25px 50px;
  }
}

main[data-page="404"] h2 {
  width: 90%;
  max-width: 960px;
  color: var(--twc-black);
  text-align: center;
  margin: 32px auto;
  font-size: 48px;
  font-weight: bold;
}

main[data-page="annonce"] {
  padding: 0;
  position: relative;
}

main[data-page="annonce"] #annonce-load, main[data-page="annonce"] #annonce-change {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main[data-page="annonce"] #annonce-details {
  z-index: 0;
  position: relative;
}

@media (width <= 424px) {
  main[data-page="annonce"] #annonce-details {
    max-height: calc(100% - 100px);
    margin-top: 100px;
  }
}

@media (width >= 425px) {
  main[data-page="annonce"] #annonce-details {
    max-height: calc(100% - 100px);
    margin-top: 100px;
  }
}

@media (width >= 768px) {
  main[data-page="annonce"] #annonce-details {
    max-height: calc(100% - 64px);
    margin-top: 64px;
  }
}

@media (width >= 1200px) {
  main[data-page="annonce"] #annonce-details {
    max-height: calc(100% - 64px);
    margin-top: 64px;
  }
}

/*# sourceMappingURL=index.f7b18caf.css.map */
