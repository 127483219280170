main[data-page="annoncement"] #annonces-projects {
    position: relative;
    box-shadow: var(--clear-thin-shadow-5);
    padding: 10px 4px;
}

main[data-page="annoncement"] #annonces-projects .loading-gif {
    width: 30px;
    height: 30px;
}

main[data-page="annoncement"] #annonces-projects:empty {
    /* display: none; */
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem {
    position: relative;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem:not(:first-child) {
    margin-top: 8px;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--twc-white);
    background-color: var(--twc-gray-800);
    padding: 8px;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem .annonces-projects-elem-poster {
    margin-top: 4px;
    padding: 6px;
}

main[data-page="annoncement"] #annonces-projects .annonces-projects-elem img {

}

@media (min-width: 0px) {
    main[data-page="annoncement"] #annonces-projects {
        display: none;
        padding: 10px 4px;
    }
}

@media (min-width: 768px) {
    main[data-page="annoncement"] #annonces-projects {
        display: none;
        padding: 10px 4px;
    }
}

@media (min-width: 1199px) {
    main[data-page="annoncement"] #annonces-projects {
        display: block;
        flex: 2;
        padding: 15px 8px;
    }
}

@media (min-width: 1200px) {
    main[data-page="annoncement"] #annonces-projects {
        display: block;
        flex: 1;
        padding: 25px 12px;
        min-width: 200px;
    }
}
