#hero-header {
    position: relative;
    text-align: right;
    z-index: 0;
}

#hero-header #leading-content {
    width: 690px;
    position: absolute;
    left: 4rem; /* 2 pour le paddig de la section et 2 pour être aligné avec le logo du header qui a 2rem de padding-left dans la section de navigation */
    text-align: left;
    /* top: 50%; */
    top: 15vh;
    /* transform: translateY(-50%); */
    z-index: 1;
}

@media only screen and (max-width: 1199px) {
    #hero-header #leading-content {
        width: 50%;
    }
}

#hero-header #leading-content #hero-leading-cta-main1,
#hero-header #leading-content #hero-leading-cta-main2 {
    line-height: 3.75rem;
    letter-spacing: -0.025rem;
    font-size: 3.75rem;
    display: block;
    width: 100%;
    font-weight: var(--extrabold);
}

#hero-header #leading-content #hero-leading-cta-main1 {
    color: var(--twc-gray-900);
}

#hero-header #leading-content #hero-leading-cta-main2 {
    color: var(--twc-pink-900);
}

#hero-header #leading-content #hero-leading-cta-text {
    font-size: 1.25rem;
    color: var(--twc-gray-600);
    line-height: 1.75rem;
}

#hero-header #leading-content a#hero-leading-cta-signup-lnk {
    display: block;
    font-size: 1.25rem;
}

#hero-header #leading-content a#hero-leading-cta-signup-btn {
    display: inline-block;
    vertical-align: top;
    margin-top: 2rem;
    margin-right: 0.75rem;
    position: relative;
}

#hero-header #leading-content button#hero-leading-cta-movies {
    display: inline-block;
    vertical-align: top;
    margin-top: 2rem;
}

#hero-header #banner-image {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 0;
}

#hero-header #banner-image img#banner-image-path {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-50%);
}

#hero-header #banner-image img#banner-image-picture {
    width: 100%;
    height: calc(100vh - var(--header-height));
}
