:root {
    --myspace-bg: #342B2D;

    --myspace-general-bg: #d9d9d9;
    --myspace-general-txt: #000000;
    --myspace-general-txt-other: #868686;
    --myspace-general-txt-other-bg: #868686;

    /* --myspace-photos: #; */

    --myspace-tabs-bg: #989898;
    --myspace-current-tab-bg: #d9d9d9;
    --myspace-tab-hover: #707070;
    --myspace-current-tab-hover: #acacac;

    --myspace-display-box-bg: #25191C;
    --myspace-display-box-title: #ff7597;
    --myspace-display-box-text: #ffffff;

    --general-height: 3rem;
    --photo-height: 9rem;
    --photo-v-padding: 1.5rem;
    --tabs-height: 3.5rem;
}

#myspace {
    width: 100%;
    height: 100%;
    padding: 0;
}

/* GENERAL 
================== */

#myspace #general {
    
}

#myspace #general div {
    
}

#myspace #general div.can-manage {
    
}

#myspace #general div.others,
#myspace #general a.others {
    
}

/* PHOTOS 
================== */

#myspace #myspace-photos {

}

#myspace #myspace-photos #myspace-photos-carousel {

}

#myspace #myspace-photos #myspace-photos-carousel:hover .photo {

}

#myspace #myspace-photos #myspace-photos-carousel .photo {

}

#myspace #myspace-photos #myspace-photos-carousel .photo:not(:first-child) {

}

#myspace #myspace-photos #myspace-photos-carousel .photo:hover {

}

#myspace #myspace-photos #myspace-photos-carousel .photo img {
    height: 100%;
}

/* TABS 
================== */

#myspace #tabs {
    height: var(--tabs-height);
}

/* DISPLAY 
================== */

#myspace #display {
    max-height: calc(100% - (var(--general-height) + var(--photo-height) + (2 * var(--photo-v-padding)) + var(--tabs-height)));
}

#myspace #display .myspace-display-section {

}

#myspace #display .myspace-display-section .myspace-box {

}

#myspace #display .myspace-display-section .myspace-box:not(:first-child) {

}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part {

}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part:not(:first-child) {

}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part:empty {
    border: none;
}

#myspace #display .myspace-display-section .myspace-box .myspace-box-part div {
    display: block;
}

/* Types de textes */

#myspace #display .titre {

}

#myspace #display .titre:empty {

}

#myspace #display .categorie {

}

#myspace #display .categorie,
#myspace #display .information {

}

#myspace #display span.information {

}